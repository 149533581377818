import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/gzhCover',
    name: 'gzhCover',
    component: () => import(/* webpackChunkName: "gzh" */ '../views/GzhCover.vue')
  },
  {
    path: '/occupancyImg',
    name: 'occupancyImg',
    component: () => import(/* webpackChunkName: "occupancyImg" */ '../views/OccupancyImg.vue')
  },
  {
    path: '/webCode',
    name: 'webCode',
    component: () => import(/* webpackChunkName: "occupancyImg" */ '../views/WebCode.vue')
  },
  {
    path: '/convert',
    name: 'Convert',
    component: () => import(/* webpackChunkName: "occupancyImg" */ '../views/Convert.vue')
  },
  {
    path: '/mdEditor',
    name: 'MdEditor',
    component: () => import(/* webpackChunkName: "MdEditor" */ '../views/MdEditor.vue')
  },
  {
    path: '/imgurl',
    name: 'Imgurl',
    component: () => import(/* webpackChunkName: "Imgurl" */ '../views/Imgurl.vue')
  },
  {
    path: '/totp',
    name: 'totp',
    component: () => import(/* webpackChunkName: "Imgurl" */ '../views/Totp.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,form)=>{
//   console.log(to);
//   let toolsToen = localStorage.getItem('')
  
// })

export default router
