
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";

@Component({})
export default class Home extends Vue {

    searchKey: string = ''
    menuList = [
        { title: "TOTP", link: "/totp", icon: this.$gm("icons/totp.png"), },
        { title: "在线匿名笔记", link: "https://note.itsse.cn/", icon: this.$gm("icons/note.png"), },
        { title: "获取公众号封面", link: "/gzhCover", icon: this.$gm('icons/gzhCover.jpg') },
        { title: "生成占位图", link: "/occupancyImg", icon: this.$gm('icons/occupancyImg.png') },
        { title: "坐标拾取", link: "https://lbs.amap.com/tools/picker", icon: this.$gm('icons/amap.jpg') },
        { title: "获取网页源代码", link: "/webCode", icon: this.$gm('icons/webCode.png') },
        { title: "TinyPng图片压缩", link: "https://tinypng.com/", icon: this.$gm('icons/tinyPng.webp') },
        // { title: "在线PS编辑", link: "https://www.onlinephotosoft.com/zh-CN/", icon: this.$gm('icons/lineps.png') },
        { title: "在线PS编辑", link: "https://www.photopea.com/", icon: this.$gm('icons/lineps.png') },
        { title: "Unlock Music", link: "https://git.unlock-music.dev/um/web.git", icon: this.$gm('icons/UnlockMusic.png') },
        { title: "货币换算", link: "/convert", icon: this.$gm('icons/exchange.png') },
        { title: "Markdown Editor", link: "/mdEditor", icon: this.$gm('icons/md.png') },
        { title: "图床", link: "/imgurl", icon: this.$gm('icons/imgurl.png') },
        { title: "catbox图床", link: "https://catbox.moe", icon: this.$gm("icons/catbox.png"), },
        { title: "刮刮乐模拟器", link: "/scratch", icon: this.$gm('icons/occupancyImg.png') },
        { title: "照片信息查看", link: "https://blog.itsse.cn/imgData/#/", icon: this.$gm('icons/imgData.png') },
        { title: "在线语言合成", link: "https://www.zaixianai.cn/voiceCompose", icon: this.$gm('icons/voiceCompose.png') },
        { title: "在线Ai作图", link: "https://ideogram.ai", icon: this.$gm('icons/ideogram.png') },
        { title: "aicutewallpapers(AI壁纸)", link: "https://aicutewallpapers.com", icon: this.$gm('icons/aicutewallpapers.png') },
        { title: "PDF工具箱PDF24", link: "https://tools.pdf24.org/zh/", icon: this.$gm('icons/pdf24.svg') },
        { title: "Stirling PDF", link: "https://tools.itsse.cn/stirling/?lang=zh_CN", icon: this.$gm('icons/StirlingPDF.svg') },
        // https://github.com/WishMelz/Stirling-PDF
        { title: "一次性电子邮箱", link: "https://temp-mail.org/zh/", icon: this.$gm('icons/temp-mail.png') },
        { title: "临时邮箱", link: "https://www.linshiyouxiang.net/", icon: this.$gm('icons/lingshiemail.png') },
        { title: "AI Random Girl Image Generate", link: "https://airandomimage.art/", icon: this.$gm('icons/airandomimage.png') },
        { title: "世界各国虚拟身份信息、地址、信用卡", link: "http://www.haoweichi.com/Index/custom_result", icon: this.$gm('icons/haoweichi.png') },
        { title: "订阅转换", link: "https://sub-web.netlify.app/", icon: this.$gm('icons/linkrest.png') },
        { title: "Github 数据分析", link: "https://ossinsight.io/", icon: this.$gm("icons/ossinsight.svg"), },
        { title: "grid布局", link: "/cssgridgenerator", icon: this.$gm("icons/cssgrid.svg"), },
        { title: "真随机数", link: "https://www.random.org/", icon: this.$gm("icons/random.png"), },
        { title: "whois查看", link: "http://who.cx/", icon: this.$gm("icons/who.png"), },
        { title: "Bigjpg(图片AI放大)", link: "https://bigjpg.com/", icon: this.$gm("icons/bigjpg.png"), },
        { title: "摸头杀生成器", link: "https://benisland.neocities.org/petpet/", icon: this.$gm("icons/toolwa.gif"), },
        { title: "JSON格式化", link: "https://tools.itsse.cn/json/", icon: this.$gm("icons/json.png"), },
        { title: "SSL证书查询", link: "https://crt.sh", icon: this.$gm("icons/crt.png"), },
    ];
    
    resMenuList: any = []

    created() {
        this.resMenuList = this.menuList

        console.log(
            `\n %c 你居然查看控制台！ %c WisheMeLzzz TOOLS \n`,
            'background:#35495e; color:#fff; padding: 1px; border-radius: 3px 0 0 3px;',
            'background:#65b587; color:#fff; padding: 1px; border-radius: 0 3px 3px 0;'
        );
    }


    private serachKeyup(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.resMenuList = this.menuList.filter((v: any) => {
                return v.title.includes(this.searchKey)
            })

        }
    }

    renderMenu() {
        return (
            <div class="list">
                {this.resMenuList.map((v: any) => {
                    return (
                        <a href={v.link} target="_blank" title={v.title}>
                            <div class="item">
                                <div class='icon'>
                                    <el-image
                                        style="width:340px; height:  190px"
                                        src={v.icon}
                                        fit="scale-down">
                                    </el-image>
                                </div>
                                <div class='text'>{v.title}</div>
                            </div>
                        </a>

                    );
                })}
            </div>
        );
    }
    renderSearch() {
        return <div class='search'>
            <el-input nativeOnKeyup={this.serachKeyup} class='inp gcenter' v-model={this.searchKey} placeholder="请输入搜索内容"></el-input>
        </div>
    }
    render() {
        return (
            <div class="Home">
                {this.renderSearch()}
                <div class="info">{this.renderMenu()}</div>
            </div>
        );
    }
}
